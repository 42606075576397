import React from "react";
import { Col, Row } from "antd";
import "./footer.scss";

const Footer = () => {
  return (
    <Row className="footer-container">
      <div style={{ marginLeft: "20px" }}>2022 Alley33</div>
    </Row>
  );
};

export default Footer;
