import { Button } from "antd";
import React, { Fragment } from "react";
import "./vt-button.scss";

const VtButton = ({ icon, name, htmlType = "submit", style = {}, ...rest }) => {
  return (
    <Fragment>
      <Button icon={icon} {...rest} htmlType={htmlType} style={style}>
        {name}
      </Button>
    </Fragment>
  );
};
export default VtButton;
