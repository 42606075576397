import React from "react";
const COLLECTION_ICON = (
  <>
    <svg viewBox="0 0 17 17" strokeWidth="1">
      <g id="Page-1">
        <g id="Product1" transform="translate(-26.000000, -352.000000)">
          <g id="collect" transform="translate(26.000000, 352.000000)">
            <path
              d="M14.3360001,14.0648594 C14.3360001,14.2062813 14.2213751,14.3207969 14.0799532,14.3207969 L0.256046869,14.3207969 C0.114625,14.3207969 -7.95807867e-16,14.2062813 -7.95807867e-16,14.0648594 L-7.95807867e-16,6.38487503 C-7.95807867e-16,6.24345316 0.114625,6.12882816 0.256046869,6.12882816 L0.768031267,6.12882816 C0.90934374,6.12882816 1.02396874,6.24345316 1.02396874,6.38487503 L1.02396874,12.5287969 C1.02528127,12.9525157 1.36839064,13.2956251 1.79200001,13.2968282 L12.5440001,13.2968282 C12.9676094,13.2956251 13.3107188,12.9525157 13.3120313,12.5287969 L13.3120313,6.38487503 C13.3120313,6.24345316 13.4266563,6.12882816 13.5679688,6.12882816 L14.0799532,6.12882816 C14.2213751,6.12882816 14.3360001,6.24345316 14.3360001,6.38487503 L14.3360001,14.0648594 Z"
              id="Path"
            ></path>
            <path
              d="M4.60796876,7.92082817 C4.60796876,8.06225003 4.49334376,8.17687503 4.35203128,8.17687503 L2.81596875,8.17687503 C2.67465628,8.17687503 2.56003127,8.06225003 2.56003127,7.92082817 L2.56003127,6.38487503 C2.56003127,6.24345316 2.67465628,6.12882816 2.81596875,6.12882816 L4.35203128,6.12882816 C4.49334376,6.12882816 4.60796876,6.24345316 4.60796876,6.38487503 L4.60796876,7.92082817 Z"
              id="Path"
            ></path>
            <path
              d="M4.60796876,11.5048282 C4.60796876,11.64625 4.49334376,11.7608751 4.35203128,11.7608751 L2.81596875,11.7608751 C2.67465628,11.7608751 2.56003127,11.64625 2.56003127,11.5048282 L2.56003127,9.96887504 C2.56003127,9.82745317 2.67465628,9.71282817 2.81596875,9.71282817 L4.35203128,9.71282817 C4.49334376,9.71282817 4.60796876,9.82745317 4.60796876,9.96887504 L4.60796876,11.5048282 Z"
              id="Path"
            ></path>
            <path
              d="M6.14403129,9.96887504 C6.14403129,9.82745317 6.25865629,9.71282817 6.39996876,9.71282817 L7.9360313,9.71282817 C8.07734377,9.71282817 8.19196877,9.82745317 8.19196877,9.96887504 L8.19196877,11.5048282 C8.19196877,11.64625 8.07734377,11.7608751 7.9360313,11.7608751 L6.39996876,11.7608751 C6.25865629,11.7608751 6.14403129,11.64625 6.14403129,11.5048282 L6.14403129,9.96887504 Z"
              id="Path"
            ></path>
            <path
              d="M11.7759688,7.92082817 C11.7759688,8.06225003 11.6613438,8.17687503 11.5200313,8.17687503 L9.98396878,8.17687503 C9.84265631,8.17687503 9.72803131,8.06225003 9.72803131,7.92082817 L9.72803131,6.38487503 C9.72803131,6.24345316 9.84265631,6.12882816 9.98396878,6.12882816 L11.5200313,6.12882816 C11.6613438,6.12882816 11.7759688,6.24345316 11.7759688,6.38487503 L11.7759688,7.92082817 Z"
              id="Path"
            ></path>
            <path
              d="M11.7759688,11.5048282 C11.7759688,11.64625 11.6613438,11.7608751 11.5200313,11.7608751 L9.98396878,11.7608751 C9.84265631,11.7608751 9.72803131,11.64625 9.72803131,11.5048282 L9.72803131,9.96887504 C9.72803131,9.82745317 9.84265631,9.71282817 9.98396878,9.71282817 L11.5200313,9.71282817 C11.6613438,9.71282817 11.7759688,9.82745317 11.7759688,9.96887504 L11.7759688,11.5048282 Z"
              id="Path"
            ></path>
            <path
              d="M3.04642188,4.43417189 C3.00551564,4.33923439 3.02793751,4.22898439 3.10275001,4.15767189 L3.97567188,3.28464065 C4.07553128,3.18412501 4.07553128,3.02181251 3.97567188,2.92118751 L2.39356251,1.33907814 C2.29435938,1.23921874 2.29435938,1.078 2.39356251,0.978140636 L3.29732815,0.0744843683 C3.39707815,-0.0248281321 3.55840628,-0.0248281321 3.65826565,0.0744843683 L5.24026565,1.65648438 C5.34089065,1.75634374 5.50320316,1.75634374 5.60382816,1.65648438 L6.47675003,0.783562503 C6.5481719,0.708859371 6.6584219,0.686328135 6.75325003,0.727234371 C6.8504844,0.765953135 6.91359377,0.860671872 6.9119844,0.96534374 L6.9119844,4.33682815 C6.9119844,4.47825002 6.79743753,4.59290228 6.65601566,4.59290228 L3.28453128,4.59290228 C3.17985938,4.59440628 3.08514065,4.53129689 3.04642188,4.43417189 Z"
              id="Path"
            ></path>
            <path
              d="M6.14403129,2.80087501 C6.00260939,2.80087501 5.88798439,2.91550001 5.88798439,3.05681251 L5.88798439,3.56879688 L5.37600002,3.56879688 C5.23457815,3.56879688 5.11995315,3.68342188 5.11995315,3.82484375 C5.11995315,3.96626565 5.23457815,4.08089065 5.37600002,4.08089065 L5.88798439,4.08089065 C6.17071876,4.08089065 6.39996876,3.85164065 6.39996876,3.56879688 L6.39996876,3.05681251 C6.39996876,2.91550001 6.28534376,2.80087501 6.14403129,2.80087501 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </>
);

export default COLLECTION_ICON;
