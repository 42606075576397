import React from "react";

const VTSvg = ({
  width = "20px",
  height = "20px",
  fill = "black",
  stroke = "none",
  strokeWidth = "1",
  viewBox = "0 0 20 20",
  path = <></>,
  onClick = () => {},
  style,
  ...rest
}) => {
  return (
    <svg
      {...rest}
      onClick={onClick}
      style={style}
      viewBox={viewBox}
      width={width}
      height={height}
      version="1.1"
      fill={fill}
      className="pointer"
      stroke={stroke}
      strokeWidth={strokeWidth}
    >
      {path}
    </svg>
  );
};

export default VTSvg;
