import React, { useState } from "react";
import LoginPresentationl from "./login-presentational";
import SecureLS from "secure-ls";
import { message } from "antd";
import { useHistory } from "react-router";
import AuthService from "service/auth-service";
import { useEffect } from "react";
import i18n from "@core/i18n/i18n";
import Roles from "@shared/utils/role";
import RoleIds from "@shared/utils/roleId";
import { useTranslation } from "react-i18next";
import OrdersService from "service/orders-service";

const LoginFunctional = () => {
  const history = useHistory();
  const { t } = useTranslation();
  var ls = new SecureLS();
  const [isLoading, setIsloading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedLang, setSelectedLang] = useState("it");
  const deLng = localStorage.getItem("lng") || "it";
  const [lang, setLang] = useState(deLng);
  const [loginForm, setLoginForm] = useState({
    userName: null,
    password: null,
  });

  //authenticate  login
  const Authentication = () => {
    setIsloading(true);
    let body = {
      userName: email,
      password: btoa(password),
    };
    AuthService.login(body)
      .then((response) => {
        setIsloading(false);
        if (response.status === 200) {
          OrdersService.getOrderStatus();
          AuthService.setAccessToken(response.data.accessToken);
          AuthService.getUserDetailByToken().then((sessionUser) => {
            AuthService.setSessionUser(sessionUser.data);
            if (sessionUser.data?.role === "CS_OPERATOR") {
              history.push("/orders-list");
            }
            if (
              sessionUser &&
              sessionUser.data &&
              sessionUser.data.role === "USER"
            ) {
              message.error("Non autorizzato");
              localStorage.clear();
            } else {
              history.push("/operators-list");
            }
          });
        } else {
          throw response;
        }
      })
      .catch((error) => {
        setIsloading(false);
        message.error(t("TOASTR.API.USER_ID_PASSWORD_NOT_MATCH"));
      });
  };

  //select and set languages
  useEffect(() => {
    if (lang) {
      localStorage.setItem("lng", lang || "it");
      i18n.changeLanguage(lang || "it");
    }
  }, [lang]);

  //previous login validation
  // const checkisAlreadyLogged = () => {
  //   let accessToken = null;
  //   try {
  //     accessToken = AuthService.getAccessToken() || null;
  //   } catch (e) {
  //     console.log(e, AuthService.getAccessToken());
  //   }
  //   console.log(accessToken);
  //   if (accessToken) {
  //     history.push("/");
  //   }
  // };

  //handle password authentication
  const handlepassword = () => {
    history.push("/forget-password", { email: Authentication.userName });
  };

  return (
    <LoginPresentationl
      isLoading={isLoading}
      email={email}
      setEmail={setEmail}
      password={password}
      handlepassword={handlepassword}
      setPassword={setPassword}
      Authentication={Authentication}
      selectedLang={selectedLang}
      setSelectedLang={setSelectedLang}
      lang={lang}
      setLang={setLang}
    />
  );
};
export default LoginFunctional;
