import Axios from "axios";
import URLs from "config/environment";
import AuthService from "service/auth-service";

const TokenInterceptor = () => {
  // Default settings for Axios request
  Axios.defaults.baseURL = URLs.apiUrl;
  Axios.defaults.headers.post["Content-Type"] = "application/json";
  Axios.defaults.validateStatus = () => true;
  Axios.interceptors.request.use(
    (config) => {
      // if (AuthService.getAccessToken()) {
      config.headers.Authorization = `Bearer ${AuthService.getAccessToken()}`;
      config.headers["content-type"] = "application/json";
      // config.headers.Authorization = `Bearers`;
      // if (config.method == "get") {
      //   config.params.sort = "createdAt,desc";
      // }
      // }
      if (localStorage.getItem("lng")) {
        config.headers["Accept-Language"] =
          `${localStorage.getItem("lng") == "it" ? "it-US" : "en-US"}` ||
          "en-US";
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
};
export default TokenInterceptor;
