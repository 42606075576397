import i18n from "i18next";
import en from "@shared/i18n/en.json";
import it from "@shared/i18n/it.json";
import { initReactI18next } from "react-i18next";

const deLng = localStorage.getItem("lng") || "it";
if (deLng) {
  localStorage.setItem("lng", deLng);
}
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    it: { translation: it },
  },
  lng: deLng,
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});

export default i18n;
