import React from "react";
const LEFT_ARROW_ICON = (
  <>
    <svg strokeWidth="1" viewBox="0 0 7 12">
      <g id="Page-1">
        <g id="Product1" transform="translate(-218.000000, -93.000000)">
          <g id="Group-12" transform="translate(209.000000, 85.500000)">
            <path
              d="M11.1065796,13.5095179 C13.9583655,10.7126081 15.4978455,9.20275181 15.7250197,8.97994907 C16.065781,8.64574496 16.1166578,8.15379083 15.7250197,7.76968893 C15.3333816,7.38558703 14.8317752,7.43548482 14.491014,7.76968893 C14.2638398,7.99249167 12.5186921,9.70405798 9.25557096,12.9043879 C8.91480968,13.238592 8.91480968,13.7804439 9.25557096,14.114648 L14.491014,19.2493469 C14.8317752,19.583551 15.3842584,19.583551 15.7250197,19.2493469 C16.065781,18.9151428 16.065781,18.3732909 15.7250197,18.0390868 L11.1065796,13.5095179 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </>
);

export default LEFT_ARROW_ICON;
