import { Input } from "antd";
import SVG from "assets/image/svg";
import React, { forwardRef, useState } from "react";
import "./vt-password.scss";

const VtPassword = forwardRef(
  (
    {
      className = "form-control",
      value,
      placeholder = "Enter Password...",
      name,
      disabled = false,
      ...rest
    },
    ref
  ) => {
    const [hidden, setHidden] = useState(true);
    const toggleShow = () => {
      setHidden(!hidden);
    };

    return (
      <>
        <Input
          ref={ref}
          name={name}
          type={hidden == true ? "password" : "text"}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          className={className}
          suffix={
            disabled == true ? (
              <></>
            ) : (
              <span className="password__show" onClick={toggleShow}>
                {hidden === true ? (
                  <img
                    height="20"
                    width="20"
                    className="pointer"
                    src={SVG.vt_eye_off}
                  />
                ) : (
                  <img
                    height="20"
                    className="pointer"
                    width="20"
                    src={SVG.vt_eye}
                  />
                )}
              </span>
            )
          }
          {...rest}
        />
      </>
    );
  }
);
export default VtPassword;
