const SVG = {
  empty: require("./empty.svg").default,
  vt_logo: require("./vt-logo.svg").default,
  vt_plus_icon: require("./vt_plus_icon.svg").default,
  vt_minus_sign: require("./minus-sign.svg").default,
  vt_logo_fold: require("./vt-logo-fold.svg").default,
  vt_eye: require("./vt-eye.svg").default,
  vt_camera: require("./vt-camera.svg").default,
  vt_eye_off: require("./vt-eye-off.svg").default,
  vt_down: require("./vt-down.svg").default,
  vt_question_circle: require("./vt-question-circle.svg").default,
  vt_plus_circle: require("./vt-plus-circle.svg").default,
  vt_edit: require("./vt-edit.svg").default,
  vt_download: require("./vt-download.svg").default,
  vt_cross: require("./vt-cross.svg").default,
  vt_notification_dot: require("./vt-notification-dot.svg").default,
  vt_notification: require("./vt-notification.svg").default,
  vt_notes: require("./vt-notes.svg").default,
  vt_loader: require("./vt-loader.svg").default,
  vt_excel_download: require("./vt_excel-download.svg").default,
  vt_view_image: require("./vt-view-image.svg").default,
  vt_search: require("./vt-search.svg").default,
  add_button: require("./add-button.svg").default,
  delete: require("./delete.svg").default,
  edit: require("./edit.svg").default,
  left_main: require("./Left main.svg").default,
  check_mark: require("./vt-checkmark.svg").default,
  dots: require("./vt-dots.svg").default,
  vt_add: require("./vt-add.svg").default,
  vt_view_eye: require("./vt-view-eye.svg").default,
  vt_delete_icon: require("./vt-delete-black.svg").default,
  vt_edit_black: require("./vt-edit-black.svg").default,
  vt_sheet_download: require("./vt-sheet-download.svg").default,
  vt_euro: require("./vt-euro.svg").default,
  vt_home: require("./vt-home.svg").default,
  vt_logout: require("./vt_logout.svg").default,
  vt_receive: require("./vt_receive.svg").default,
  vt_right: require("./vt_right.svg").default,
  vt_back_arrow: require("./vt-back-arrow.svg").default,
  vt_download_solid: require("./vt-download-solid.svg").default,
  vt_filter_clear: require("./vt_filter_clear.svg").default,
  vt_filter: require("./vt_filter.svg").default,
  vt_wrong: require("./vt-wrong.svg").default,
  vt_contact: require("./vt_contact.svg").default,
  vt_box: require("./vt_box.svg").default,
  vt_homes: require("./Group 3.svg").default,
  vt_create_qoute: require("./vt_create_qoute.svg").default,
  vt_tick: require("./vt_tick.svg").default,
  vt_star: require("./vt_star.svg").default,
  vt_star_tick: require("./vt_star_tick.svg").default,
  vt_help: require("./vt_help.svg").default,
  vt_filter_by: require("./vt_filter_by.svg").default,
  vt_better_screen_icon: require("./vt-better-screen-icon.svg").default,
  vt_location: require("./vt-location.svg").default,
  // vt_fill_drip_solid: require("./vt-fill-drip-solid.svg").default,
  // pwa icon
  pwa_menu_icon: require("./pwa-menu-icon.svg").default,
  pwa_up_arrow: require("./pwa-up-arrow.svg").default,
  pwa_left_arrow: require("./pwa-left-arrow.svg").default,
  pwa_down_solid: require("./pwa-down-solid.svg").default,
  pwa_plus_solid: require("./vt-plus-solid.svg").default,
  pwa_clipboard: require("./clipboards.svg").default,

  Menu_Icon: require("./menu-icon.svg").default,
  alley33_logo: require("./alley-logo.svg").default,
  Log_Right: require("./log-right-logo.svg").default,
  Log_Right: require("./log-right-logo.svg").default,
  registration_logo: require("./registration-logo.svg").default,
  DUMMY: require("./dummy.svg").default,
};
export default SVG;
