import React from "react";
import { Row, Divider, Col, Radio } from "antd";
import SVG from "./../../assets/image/svg/index";
import "./login.scss";
import VtTextField from "@shared/component/vt-textfield/vt-text-field";
import VtButton from "@shared/component/vt-button/vt-button";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import validator from "@shared/validators/validator";
import VtFormErrMsg from "@shared/component/vt-form-err-msg/vt-form-err-msg";
import VtPassword from "@shared/component/vt-password/vt-password";
import { useTranslation } from "react-i18next";
import VtLabel from "@shared/component/vt-label/vt-label";

const LoginPresentationl = ({
  isLoading,
  email,
  setEmail,
  password,
  handlepassword,
  setPassword,
  Authentication,
  lang,
  setLang,
}) => {
  const { t } = useTranslation();
  const { control, errors, trigger } = useForm({
    defaultValues: { email: null, password: null },
    mode: "all",
  });

  function onSubmit() {
    trigger().then((res) => {
      if (res) {
        Authentication();
      }
    });
  }

  const validatormsg = {
    email: {
      required: { value: true, message: "Required Field" },
      pattern: { value: validator.EMAIL, message: "Please Insert Valid email" },
    },
    password: {
      required: { value: true, message: "Required Field" },
      minLength: {
        value: 6,
        message: "Password valid min 6 charecter",
      },
      maxLength: { value: 15, message: "Password valid max 15 charecter" },
    },
  };

  return (
    <Row className="login-wrapper">
      <Col xl={12} lg={12} md={12} sm={24} xs={24} className="left-pannel">
        <Row className="left-row">
          <Col xl={24} lg={24} md={24} sm={24} xs={24} className="alley-logo">
            <Link
              to={{
                pathname: "/",
              }}
            >
              <img src={SVG.alley33_logo} alt="alley33_logo" />
            </Link>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24} className="accedi">
            {t("COMMON.LOGIN")}
          </Col>
          {/* <Col
            xl={24}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className="media-container"
          >
            <Radio.Group
              onChange={(e) => {
                e.preventDefault();
                setLang(e.target.value);
              }}
              value={lang}
            >
              <Radio key={"en"} value={"en"}>
                EN
              </Radio>
              <Radio key={"it"} value={"it"}>
                IT (italiano)
              </Radio>
            </Radio.Group>
          </Col> */}
          <Col
            xl={24}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            style={{ marginTop: 10 }}
          >
            <Controller
              control={control}
              name="email"
              rules={validatormsg.email}
              render={({ ref, ...rest }) => (
                <VtTextField
                  {...rest}
                  ref={ref}
                  label={t("COMMON.EMAIL_ADDRESS")}
                  placeholder={t("COMMON.EMAIL_ADDRESS")}
                  border="basic"
                  value={email}
                  onInput={(e) => {
                    setEmail(e.target.value);
                    trigger("email");
                  }}
                  style={{ height: "40px", border: "1px solid #28bcf7" }}
                />
              )}
            />
            <VtFormErrMsg errors={errors.email} />
          </Col>
          <Col
            xl={24}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            style={{ margin: "15px 0px" }}
          >
            <span>{t("COMMON.PASSWORD")}</span>
            <Controller
              control={control}
              name="password"
              rules={validatormsg.password}
              render={({ ref, ...rest }) => (
                <VtPassword
                  {...rest}
                  ref={ref}
                  label={t("COMMON.PASSWORD")}
                  placeholder={t("COMMON.PASSWORD")}
                  border="basic"
                  value={password}
                  onInput={(e) => {
                    setPassword(e.target.value);
                    trigger("password");
                  }}
                />
              )}
            />
            <VtFormErrMsg errors={errors.password} />
          </Col>
          <Col>
            <VtLabel
              onClick={handlepassword}
              title={t("LOGIN_PAGE.COMMON.FORGOT_PASSWORD")}
              className="form-label primary pointer"
            />
          </Col>
          <Col
            xl={24}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            style={{ marginTop: "5px" }}
          >
            <VtButton
              name={t("COMMON.LOGIN")}
              block
              type="red"
              onClick={onSubmit}
              loading={isLoading}
              disabled={Object.keys(errors).length > 0}
              style={{
                marginBottom: "174px",
                backgroundColor: "#28bcf7",
                color: "white",
              }}
            />
          </Col>
          {/* 
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Link
              to={{
                pathname: "/Registration",
              }}
            ></Link> 
          </Col>*/}
        </Row>
      </Col>

      <Col
        xl={12}
        lg={12}
        md={12}
        className="right-pannel"
        style={{ backgroundImage: `url(${SVG.registration_logo})` }}
      ></Col>
    </Row>
  );
};

export default LoginPresentationl;
