import React from "react";
import { Route, Redirect } from "react-router-dom";
import AuthService from "service/auth-service";

const PrivateRoute = ({
  component: Component,
  roles,
  exact = true,
  ...rest
}) => (
  <Route
    {...rest}
    exact={exact}
    render={(props) => {
      const access_token = AuthService.getAccessToken();
      if (!access_token) {
        // not logged in so redirect to login page with the return url
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }

      // // check if route is restricted by role
      // if (roles && roles.indexOf(currentUser.role) === -1) {
      //   // role not authorised so redirect to home page
      //   return <Redirect to={{ pathname: "/" }} />;
      // }

      // authorised so return component
      return <Component exact={exact} {...props} />;
    }}
  />
);
export default PrivateRoute;
