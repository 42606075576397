import React from "react";
const ICON_ICON = (
  <>
    <svg viewBox="0 0 17 17" strokeWidth="1">
      <g id="Page-1">
        <g id="Product1" transform="translate(-28.000000, -397.000000)">
          <g id="water-drop" transform="translate(28.000000, 397.000000)">
            <path
              d="M5.5,0 C5.5,0 0,6.73573689 0,10.4414421 C0,13.5116189 2.46216667,16 5.5,16 C8.53783333,16 11,13.5116189 11,10.4414421 C11,6.73573689 5.5,0 5.5,0 Z M3.10016667,10.8058365 C3.10016667,11.6865591 3.50838889,13.430711 3.79316667,14.1570293 C1.93416667,13.2343087 1.44283333,11.4240716 1.44283333,10.1653671 C1.44283333,8.20566664 3.69416667,5.33312746 4.97322222,2.6619316 C4.83266667,4.06454103 3.10016667,8.13834633 3.10016667,10.8058365 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </>
);

export default ICON_ICON;
