import FILL_COLOR from "../common/vt-fill-drip-solid";
import CATEGORY_ICON from "./category-icon";
import COLLECTION_ICON from "./collection-icon";
import DASHBOARD_ICON from "./dashboard-icon";
import FORMAT_ICON from "./format-icon";
import ICON_ICON from "./icon-icon";
import MY_PROFILE_ICON from "./my-profile-icon";
import PRODUCT_ICON from "./products-icon";
import QUOTE_MGMT_ICON from "./quote-mgmt-icon";
import SERVICE_ICON from "./service-icon";
import TEAM_ICON from "./team-icon";
import TECHNOLOGY_ICON from "./technology-icon";
import USER_MGMT_ICON from "./user-mgmt-icon";

const SIDEBAR = {
  DASHBOARD: DASHBOARD_ICON,
  PRODUCTS: PRODUCT_ICON,
  TECHNOLOGY: TECHNOLOGY_ICON,
  FORMAT: FORMAT_ICON,
  CATEGORY: CATEGORY_ICON,
  COLLECTION: COLLECTION_ICON,
  ICON: ICON_ICON,
  USER_MGMT: USER_MGMT_ICON,
  QUOTE_MGMT: QUOTE_MGMT_ICON,
  TEAM: TEAM_ICON,
  SERVICE: SERVICE_ICON,
  MY_PROFILE: MY_PROFILE_ICON,
  RAL_COLOR: FILL_COLOR,
};

export default SIDEBAR;
