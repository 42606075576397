import React from "react";

const FILL_COLOR = (
  <>
    <svg strokeWidth="1" fill="#fff" viewBox="0 0 576 512">
      <g>
        <g>
          <path
            fill="currentColor"
            d="M512 320s-64 92.65-64 128c0 35.35 28.66 64 64 64s64-28.65 
      64-64-64-128-64-128zm-9.37-102.94L294.94 9.37C288.69 3.12 280.5 0 272.31 0s-16.38 3.12-22.62 9.37l-81.58 81.58L81.93 4.76c-6.25-6.25-16.38-6.25-22.62 0L36.69 27.38c-6.24 6.25-6.24 16.38 0 22.62l86.19 86.18-94.76 94.76c-37.49 37.48-37.49 98.26 0 135.75l117.19 117.19c18.74 18.74 43.31 28.12 67.87 28.12 24.57 0 49.13-9.37 67.87-28.12l221.57-221.57c12.5-12.5 12.5-32.75.01-45.25zm-116.22 70.97H65.93c1.36-3.84 3.57-7.98 7.43-11.83l13.15-13.15 81.61-81.61 58.6 
      58.6c12.49 12.49 32.75 12.49 45.24 0s12.49-32.75 
      0-45.24l-58.6-58.6 58.95-58.95 162.44 162.44-48.34 48.34z"
          />
        </g>
      </g>
      <g>
        {/* <g>
          <path
            d="M161.174,62.995c-40.095,0-72.713,32.62-72.713,72.713c0,40.094,32.619,72.713,72.713,72.713s72.713-32.619,72.713-72.713
			S201.269,62.995,161.174,62.995z M161.174,178.264c-23.466,0-42.556-19.091-42.556-42.556c0-23.466,19.09-42.556,42.556-42.556
			c23.466,0,42.556,19.091,42.556,42.556S184.64,178.264,161.174,178.264z"
          />
        </g> */}
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  </>
);
export default FILL_COLOR;
