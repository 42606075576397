import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import AuthService from "service/auth-service";

const ScrollToTop = ({ children, location: { pathname } }) => {
  // useEffect(() => {
  //   if (window.screen.width <= 600) {
  //     getNotificationListApi();
  //   }
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // }, [pathname]);

  // const getNotificationListApi = () => {
  //   let params = {};
  //   params.isRead = "";
  //   params.page = 0;
  //   params.size = 0;
  //   params.sort = "createdAt,desc";
  //   NotificationService.getnotificationList({ params })
  //     .then((res) => {
  //       if (res && res.data && res.data.content) {
  //         let a =
  //           res.data.content.filter((data) => {
  //             return data.isRead === false;
  //           }) || [];
  //         AuthService.setNotificationCount(a.length);
  //       }
  //     })
  //     .catch(() => {});
  // };

  return children || null;
};

export default withRouter(ScrollToTop);
