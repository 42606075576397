import React from "react";
import { message } from "antd";
import Axios from "axios";
import URLs from "config/environment";
import { useTranslation } from "react-i18next";

const getErrorMsg = (res) => {
  let errorMsg = res?.data?.message;

  if (!errorMsg) {
    let constraints = (res?.data?.constraintViolations || [])[0] || {};
    errorMsg = constraints?.message;
  }
  return errorMsg;
};

const ErrorInterceptor = () => {
  // Default settings for Axios request
  const { t } = useTranslation();
  Axios.defaults.baseURL = URLs.apiUrl;
  Axios.defaults.headers.post["Content-Type"] = "application/json";
  Axios.defaults.validateStatus = () => true;
  Axios.interceptors.response.use(
    (res) => {
      if (res.status && parseInt(res.status, 0) > 205) {
        const resMessage = getErrorMsg(res)
          ? t(`TOASTR.API.${getErrorMsg(res)}`)
          : "Something went wrong";
        switch (res.status) {
          case 403:
            return Promise.reject("Something went wrong" + res.data);
          default:
            message.error({
              content: resMessage,
              className: "api-error-toastr",
            });
            return Promise.reject({ ...res.data });
        }
      } else {
        return res;
      }
    },
    (error) => Promise.reject(error)
  );
  return {};
};

export default ErrorInterceptor;
