import axios from "axios";
import { LoginUrl } from "./url";
import SecureLS from "secure-ls";
var ls = new SecureLS();

const login = (body) => {
  let newAxiosInstance = axios.create(); // ignoring default interceptor and other defaults
  const headers = {
    "Accept-Language": "en-US",
    "Content-Type": "application/json",
    accept: "application/json",
  };
  return newAxiosInstance.post(LoginUrl.LOG_IN, body, { headers });
};

const forgetpassword = (request, headers = {}, params = {}) => {
  let newAxiosInstance = axios.create(); // ignoring default interceptor and other defaults
  return newAxiosInstance.put(LoginUrl.FORGET_PASSWORD, request, {
    headers: headers,
    params: params,
  });
};

const resetPassword = (request, headers = {}, params = {}) => {
  let newAxiosInstance = axios.create(); // ignoring default interceptor and other defaults
  return newAxiosInstance.put(LoginUrl.RESET_PASSWORD, request, {
    Authorization: "Bearer " + getAccessToken(),
    headers: { ...headers, Authorization: "Bearer " + getAccessToken() },
    params: params,
  });
};

export const getUserDetailByToken = () => {
  return axios.get(LoginUrl.GET_CLIENT_DETAIL_BY_TOKEN);
};
const getCurrentUser = () => {
  return ls.get("CURRENT_USER")
    ? JSON.parse(ls.get("CURRENT_USER"))
    : null || null;
};
const getAccessToken = () => {
  return localStorage.getItem("ACCESS_TOKEN");
};
const setAccessToken = (token) => {
  return localStorage.setItem("ACCESS_TOKEN", token);
};
const setSessionUser = (sessionUser) => {
  return localStorage.setItem("SESSION_USER", JSON.stringify(sessionUser));
};
const getSessionUser = () => {
  let sessionUser = localStorage.getItem("SESSION_USER");
  return JSON.parse(sessionUser);
};
const getLang = async () => {
  return (localStorage.getItem("language") || "en") + "-US";
};
const getTokenHeader = () => {
  return {
    Authorization: "Bearer " + getAccessToken(),
    "content-type": "application/json",
    "Accept-Language": "it-US" || getLang(),
  };
};

const AuthService = {
  login,
  getCurrentUser,
  getAccessToken,
  getTokenHeader,
  setAccessToken,
  forgetpassword,
  getUserDetailByToken,
  setSessionUser,
  getSessionUser,
  resetPassword,
};

export default AuthService;
