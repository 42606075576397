import React, { forwardRef, useState, useEffect } from "react";
import { Input } from "antd";

export const GetColorpickers = ({
  value,
  color,
  setColors,
  setValue,
  onPickerChange = () => {},
}) => {
  return (
    <Input
      style={{ width: "45px", padding: " 0px" }}
      type="color"
      defaultValue={value}
      value={"#" + color}
      onChange={(e) => {
        setColors(e.target.value.toString().slice(1, e.target.value.length));
        setValue(e.target.value);
        onPickerChange(e.target.value);
      }}
    />
  );
};

const VtColorPicker = forwardRef(
  ({ onPickerChange, name, colorValue = "ffffff", ...rest }, ref) => {
    const [color, setColors] = useState(colorValue);
    const [value, setValue] = useState(null);

    useEffect(() => {
      setColors(colorValue || "ffffff");
    }, [colorValue]);

    return (
      <>
        <Input
          {...rest}
          ref={ref}
          name={name}
          prefix={"#"}
          type="text"
          maxLength="6"
          defaultValue={value}
          value={color}
          onChange={(e) => {
            setColors(e.target.value);
            setValue("#" + e.target.value);
            onPickerChange("#" + e.target.value);
          }}
          className="form-control"
          suffix={
            <GetColorpickers
              value={value}
              color={color}
              setColors={setColors}
              setValue={setValue}
              onPickerChange={onPickerChange}
            />
          }
        />
      </>
    );
  }
);
export default VtColorPicker;
