// import AdminLazyRouting from "@core/lazy-routing/admin-lazy-routing";
import SVGPath from "assets/image/svg/SVGPath";

const Routes = [
  {
    path: "/client-list",
    layout: "/",
    index: "/:pageIndex",
    title: "Clienti",
    icon: {
      SVG: SVGPath.CMS.SIDEBAR.DASHBOARD,
    },
    // component: AdminLazyRouting.AdminVendorFunctional,
    exact: true,
    children: [
      {
        path: "/upload-products",
        layout: "/",
        index: "/:pageIndex",
        title: "Upload Proditti",
        icon: {
          SVG: SVGPath.CMS.SIDEBAR.DASHBOARD,
        },
        // component: AdminLazyRouting.AdminVendorFunctional,
        exact: true,
      },
    ],
  },

  {
    path: "/upload-products",
    layout: "/admin",
    index: "/:pageIndex",
    title: "Upload Proditti",
    icon: {
      SVG: SVGPath.CMS.SIDEBAR.DASHBOARD,
    },
    // component: AdminLazyRouting.AdminVendorFunctional,
    exact: true,
    children: [],
  },

  {
    path: "/discount-codes",
    layout: "/admin",
    index: "/:pageIndex",
    title: "Codici Sconti",
    icon: {
      SVG: SVGPath.CMS.SIDEBAR.DASHBOARD,
    },
    // component: AdminLazyRouting.AdminVendorFunctional,
    exact: true,
    children: [],
  },
  {
    path: "/multiple-discounts",
    layout: "/admin",
    index: "/:pageIndex",
    title: "Sconti multipli",
    icon: {
      SVG: SVGPath.CMS.SIDEBAR.DASHBOARD,
    },
    // component: AdminLazyRouting.AdminVendorFunctional,
    exact: true,
    children: [],
  },
  {
    path: "/loyalty-points",
    layout: "/admin",
    index: "/:pageIndex",
    title: "Punti fedelta",
    icon: {
      SVG: SVGPath.CMS.SIDEBAR.DASHBOARD,
    },
    // component: AdminLazyRouting.AdminVendorFunctional,
    exact: true,
    children: [],
  },
  {
    path: "/go-out",
    layout: "/admin",
    index: "/:pageIndex",
    title: "Esci",
    icon: {
      SVG: SVGPath.CMS.SIDEBAR.DASHBOARD,
    },
    // component: AdminLazyRouting.AdminVendorFunctional,
    exact: true,
    children: [],
  },
];
export default Routes;
