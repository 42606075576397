import React, { forwardRef } from "react";
import VtLabel from "../vt-label/vt-label";
import { Input } from "antd";
import "./vt-text-field.scss";
import VtColorPicker from "../vt-color-picker/vt-color-picker";

const VtTextField = forwardRef(
  (
    {
      placeholder = "Enter your input here",
      value,
      labelStyle,
      className = "form-control",
      onChange = "",
      style,
      type = "text",
      label = null,
      labelClassName = "form-label",
      isTooltipContent = null,
      isTooltipIcon = null,
      name,
      isEnterNext = true,
      border,
      isNext,
      prefix,
      min,
      max,
      allowClear = false,
      isRequiredClass = false,
      minLength,
      maxLength,
      ...rest
    },
    ref
  ) => {
    const tabNext = (e, ref) => {
      if (
        e.key == "Enter" &&
        ref &&
        ref.current &&
        ref.current.input.attributes.tabindex &&
        ref.current.input.attributes.tabindex.value
      ) {
        let curIndex = ref.current.input.attributes.tabindex.value;
        let tabbables = document.querySelectorAll("[tabindex]") || [];
        for (var i = 0; i < tabbables.length; i++) {
          // loop through each element
          if (tabbables[i].tabIndex == parseInt(curIndex) + 1) {
            // check the tabindex to see if it's the element we want
            if (tabbables[i].nodeName == "BUTTON") {
              tabbables[i].click();
            } else {
              tabbables[i].focus(); // if it's the one we want, focus it and exit the loop
            }
            break;
          }
        }
      }
    };

    const getRenderer = () => {
      switch (type) {
        case "text":
          return (
            <Input
              allowClear={allowClear}
              prefix={prefix}
              style={style}
              ref={ref}
              minLength={minLength}
              maxLength={maxLength}
              value={value}
              name={name}
              placeholder={placeholder}
              className={
                className +
                " " +
                border +
                ` ${isRequiredClass ? "required" : ""}`
              }
              onChange={onChange}
              onKeyPress={(e) => {
                if (isEnterNext) {
                  tabNext(e, ref);
                }
              }}
              {...rest}
            />
          );
        case "phoneNumber":
          return (
            <Input
              allowClear={allowClear}
              prefix={prefix}
              ref={ref}
              {...rest}
              type="tel"
              pattern="[0-9]*"
              maxLength={maxLength}
              style={style}
              value={value}
              name={name}
              placeholder={placeholder}
              className={
                className +
                " " +
                border +
                ` ${isRequiredClass ? "required" : ""}`
              }
              onKeyPress={(e) => {
                if (isNext) {
                  tabNext(e, ref);
                }
              }}
            />
          );
        case "email":
          return (
            <Input
              allowClear={allowClear}
              prefix={prefix}
              style={style}
              {...rest}
              name={name}
              type="email"
              ref={ref}
              placeholder={placeholder}
              className={className + ` ${isRequiredClass ? "required" : ""}`}
              onChange={onChange}
              value={value}
              onKeyPress={(e) => {
                if (isNext) {
                  tabNext(e, ref);
                }
              }}
            />
          );
        case "number":
          return (
            <Input
              allowClear={allowClear}
              prefix={prefix}
              {...rest}
              style={style}
              name={name}
              value={value}
              min={min}
              max={max}
              type="number"
              pattern={/[+-]?([0-9]*[.])?[0-9]+/}
              ref={ref}
              placeholder={placeholder}
              className={className + ` ${isRequiredClass ? "required" : ""}`}
              onChange={onChange}
              onKeyPress={(e) => {
                if (isNext) {
                  tabNext(e, ref);
                }
              }}
            />
          );

        case "date":
          return (
            <Input
              allowClear={allowClear}
              prefix={prefix}
              {...rest}
              style={style}
              name={name}
              value={value}
              type="date"
              ref={ref}
              placeholder={placeholder}
              className={className + ` ${isRequiredClass ? "required" : ""}`}
              onChange={onChange}
              onKeyPress={(e) => {
                if (isNext) {
                  tabNext(e, ref);
                }
              }}
            />
          );

        case "color":
          return (
            <VtColorPicker
              allowClear={allowClear}
              prefix={prefix}
              style={style}
              {...rest}
              ref={ref}
              value={value}
              name={name}
              onPickerChange={(e) => {
                onChange(e);
              }}
            />
          );
        default:
          return (
            <Input
              allowClear={allowClear}
              {...rest}
              prefix={prefix}
              style={style}
              ref={ref}
              value={value}
              name={name}
              className={className + ` ${isRequiredClass ? "required" : ""}`}
              onChange={onChange}
              onKeyPress={(e) => {
                if (isNext) {
                  tabNext(e, ref);
                }
              }}
            />
          );
      }
    };

    return (
      <>
        {label && (
          <VtLabel
            style={labelStyle}
            className={labelClassName}
            title={label}
            isTooltipIcon={isTooltipIcon}
            isTooltipContent={isTooltipContent}
          />
        )}
        {getRenderer()}
      </>
    );
  }
);

export default VtTextField;
