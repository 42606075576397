import React from "react";
import { ClientUrl, OrdersUrl } from "./url";
import axios from "axios";
import { LocalStorage } from "config/storage";
import AuthService from "./auth-service";

//client list
const getOrdersApi = (query, params) => {
  return axios.get(OrdersUrl.GET_ORDERS, { params });
};
const getOrderStatus = (params) => {
  return axios.get(OrdersUrl.ORDERS_STATUS, { params });
};
// //get client by Id
// const getLoyaltyPointId = (id) => {
//   return axios.get(LoyaltytUrl.GET_LOYALTY_POINTS + "/" + id);
// };
// //delete client
// const handleDelete = (id) => {
//   return axios.delete(LoyaltytUrl.GET_LOYALTY_POINTS + "/" + id);
// };

// const getOrdersApi = async (query, params) => {
//   const req = {
//     method: "GET",
//     headers: {
//       "content-type": "application/json",
//       Authorization: "Bearer " + AuthService.getAccessToken(),
//     },
//     params,
//   };
//   return await axios(OrdersUrl.GET_ORDERS, req);
// };

const getOrderById = (id) => {
  return axios.get(OrdersUrl.GET_ORDERS + "/" + id);
};

// const getOrderStatus = async (params) => {
//   const req = {
//     method: "GET",
//     params,
//   };
//   return await axios(OrdersUrl.ORDERS_STATUS, req);
// };
const orderStatusUpdate = (id, values) => {
  console.log(id);
  let req = {
    method: "PATCH",
    url: OrdersUrl.UPDATE_ORDERS + id,
    headers: { ...AuthService.getTokenHeader(), "Accept-Language": "en-US" },
    data: {
      ...values,
      ordersStatusId: 3,
      paymentAt: null,
    },
  };
  return axios(req);
};

const changeOrderStatus = (ordersStatusId, refundId, params) => {
  let req = {
    method: "PATCH",
    url: OrdersUrl.UPDATE_ORDERS + refundId,
    headers: { ...AuthService.getTokenHeader(), "Accept-Language": "en-US" },
    data: {
      ordersStatusId: ordersStatusId,
    },
    params,
  };
  return axios(req);
};

const OrdersService = {
  getOrdersApi,
  getOrderById,
  orderStatusUpdate,
  changeOrderStatus,
  getOrderStatus,
};

export default OrdersService;
