import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import ScrollToTop from "utils/ScrollToTop";
import Routes from "routes";
import ErrorInterceptor from "@core/interceptors/error-interceptor";

const App = () => {
  ErrorInterceptor();
  return (
    <BrowserRouter basename="/">
      <ScrollToTop>
        <Routes />
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default App;
