import React from "react";
import { useTranslation } from "react-i18next";

const VtFormErrMsg = ({ errors = {} }) => {
  const { t } = useTranslation();
  // clg(errors);
  // const getError = () => {
  //   switch (errors["type"]) {
  //     case "required":
  //       return <div className="required">This field is required</div>;
  //     case "pattern":
  //       return (
  //         <div className="required">
  //           {errors["message"] || "This field is not a valid format"}
  //         </div>
  //       );
  //     default:
  //       return <div className="required">{errors["message"]}</div>;
  //   }
  // };

  return (
    <>
      {(errors && Object.keys(errors).length > 0 && (
        <div className="required">
          {errors["message"]
            ? `${t(`${errors["message"]}`)}`
            : t("COMMON.FIELD_REQIURED")}
        </div>
      )) ||
        null}
    </>
  );
};

export default VtFormErrMsg;
