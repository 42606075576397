import VtButton from "@shared/component/vt-button/vt-button";
import { Modal, Row, Col, message } from "antd";
import React, { useState } from "react";
import ForgetPasswordPresentationl from "./forget-password-presentational";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import AuthService from "service/auth-service";

const ForgetPasswordFunctional = () => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(false);
  const [showErrorPopUp, setShowErrorPopUp] = useState("");
  const history = useHistory();
  const [email, setEmail] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);

  //Forgot Password Mail sent
  const sendEmailClicked = () => {
    setBtnLoading(true);
    let request = {
      email: email,
    };
    AuthService.forgetpassword(request)
      .then((res) => {
        if (res.status == 204) {
          message.error("Invalid email");
        } else if (res.status == 200) {
          message.success("Email sent successfully");
          setEmail("");
          history.goBack();
        }
        setBtnLoading(false);
      })
      .catch((e) => {
        message.error(t("TOASTR.API.USER_ID_PASSWORD_NOT_MATCH"));
        setBtnLoading(false);
      });
  };
  //login screen call
  const backToLogin = () => {
    history.goBack();
  };
  return (
    <>
      <ForgetPasswordPresentationl
        sendEmailClicked={sendEmailClicked}
        email={email}
        setEmail={setEmail}
        btnLoading={btnLoading}
        backToLogin={backToLogin}
      />
      ;
      <Modal visible={showErrorPopUp}>
        <Row style={{ fontSize: "14px", textAlign: "center" }}>
          <Col xl={24} lg={24} md={24}>
            {errorMessage}
          </Col>
          <Col
            xl={24}
            lg={24}
            md={24}
            xs={24}
            style={{
              marginTop: "15px",
              textAlign: "center",
            }}
          >
            <VtButton
              type="primary"
              block
              name={t("COMMON.OKAY")}
              onClick={() => setShowErrorPopUp(false)}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ForgetPasswordFunctional;
