import DELETE_ICON from "./common/delete-icon";
import EDIT_ICON from "./common/edit-icon";
import FILE_DOWNLOAD_ICON from "./common/file-download-icon";
import HAMBERGER_ICON from "./common/hamburger-icon";
import IMAGE_ICON from "./common/image-icon";
import LEFT_ARROW_ICON from "./common/left-arrow-icon";
import SIDEBAR from "./sidebar/sidbar-icon";

const SVGPath = {
  CMS: {
    SIDEBAR: SIDEBAR,
  },
  COMMON: {
    LEFT_ARROW_ICON: LEFT_ARROW_ICON,
    HAMBERGER_ICON: HAMBERGER_ICON,
    DELETE_ICON: DELETE_ICON,
    IMAGE_ICON: IMAGE_ICON,
    EDIT_ICON: EDIT_ICON,
    FILE_DOWNLOAD_ICON: FILE_DOWNLOAD_ICON,
  },
};

export default SVGPath;
