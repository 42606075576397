import React, { forwardRef, useEffect, useState } from "react";
import SVG from "assets/image/svg";
import "./vt-img.scss";
import { Popover } from "antd";
import VTSvg from "../vt-svg/vt-svg";
import SVGPath from "assets/image/svg/SVGPath";

const VtImg = forwardRef(
  (
    {
      src,
      alt = "img",
      height = "auto",
      width = "auto",
      style,
      wrapperClassName = "",
      className = "",
      onLoad = () => {},
      onError = () => {},
      onClick = () => {},
      key = Math.floor(100000 * Math.random()),
      ...rest
    },
    ref
  ) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [isValidSrc, setIsValidSrc] = useState(!!src);
    useEffect(() => {
      setIsValidSrc(!!src);
      setImageLoaded(false);
    }, [src]);
    return (
      <div
        key={key}
        className={`smooth-img-wrapper ${wrapperClassName}`}
        ref={ref}
        onClick={onClick}
      >
        {isValidSrc ? (
          <img
            {...rest}
            style={style}
            className={`smooth-image image-${
              imageLoaded ? "visible" : "hidden"
            }  ${className} `}
            src={src}
            height={height}
            width={width}
            onError={() => {
              setIsValidSrc(false);
              onError();
            }}
            onLoad={(e) => {
              setImageLoaded(true);
              setIsValidSrc(true);
              onLoad(e);
            }}
          ></img>
        ) : (
          <Popover content={alt} visible={false}>
            <div style={style} className="smooth-img-alt">
              <VTSvg path={SVGPath.COMMON.IMAGE_ICON} />
            </div>
          </Popover>
        )}
        {isValidSrc && !imageLoaded && (
          <div className="smooth-preloader">
            <img className="img" src={SVG.vt_loader} />
          </div>
        )}
      </div>
    );
  }
);

export default VtImg;
