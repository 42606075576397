import React from "react";
import { Row, Divider, Col, Radio, message } from "antd";
import SVG from "./../../assets/image/svg/index";

import { useHistory } from "react-router";
import VtTextField from "@shared/component/vt-textfield/vt-text-field";
import VtButton from "@shared/component/vt-button/vt-button";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import validator from "@shared/validators/validator";
import VtFormErrMsg from "@shared/component/vt-form-err-msg/vt-form-err-msg";
import VtPassword from "@shared/component/vt-password/vt-password";
import { useTranslation } from "react-i18next";
import VtLabel from "@shared/component/vt-label/vt-label";
const ResetPasswordPresentationl = (props = {}) => {
  const { t } = useTranslation();
  const { userData = {} } = props;
  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      password: null,
      resetPassword: null,
      ...userData,
    },
    mode: "all",
  });
  const history = useHistory();
  const onSubmit = async (data) => {
    if (data.password !== data.confirmPassword) {
      message.warning("Passwords doesnt match");
      return;
    }
    let payload = {
      password: data.password,
    };
    try {
      message.success("Success");
      history.push("/operators-list");
    } catch (error) {}
  };
  const validatormsg = {
    password: {
      required: { value: true, message: "L'e-mail è obbligatoria" },
      pattern: { value: validator.EMAIL, message: "Inserire un'email valida" },
    },
    retype_password: {
      required: { value: true, message: "E 'richiesta la password" },
      minLength: {
        value: 6,
        message: "Password non valida minimo 6 caratteri",
      },
      maxLength: { value: 15, message: "Password non valida max 15 caratteri" },
    },
  };

  return (
    <Row className="login-wrapper">
      <Col xl={12} lg={12} md={12} sm={24} xs={24} className="left-pannel">
        <Row className="left-row">
          <Col xl={24} lg={24} md={24} sm={24} xs={24} className="alley-logo">
            <Link
              to={{
                pathname: "/",
              }}
            >
              <img src={SVG.alley33_logo} alt="alley33_logo" />
            </Link>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24} className="accedi">
            Accedi
          </Col>

          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <span>{t("COMMON.PASSWORD")}</span>
            <Controller
              control={control}
              name="password"
              rules={validatormsg.password}
              render={({ ref, ...rest }) => (
                <VtPassword
                  {...rest}
                  ref={ref}
                  label={t("COMMON.PASSWORD")}
                  placeholder={t("COMMON.PASSWORD")}
                  border="basic"
                />
              )}
            />
            <VtFormErrMsg errors={errors.password} />
          </Col>
          <Col
            xl={24}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            style={{ margin: "15px 0px" }}
          >
            <span>{t("COMMON.REPEAT_PASSWORD")}</span>
            <Controller
              control={control}
              name="confirmPassword"
              rules={validatormsg.password}
              render={({ ref, ...rest }) => (
                <VtPassword
                  {...rest}
                  ref={ref}
                  label={t("COMMON.REPEAT_PASSWORD")}
                  placeholder={t("COMMON.REPEAT_PASSWORD")}
                  border="basic"
                />
              )}
            />
            <VtFormErrMsg errors={errors.retype_password} />
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Link
              to={{
                pathname: "/login",
              }}
            >
              <p>Login</p>
            </Link>
          </Col>
          <Col
            xl={24}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            style={{ marginTop: "5px" }}
          >
            <VtButton
              name="Accedi"
              block
              type="red"
              // onClick={Authentication}
              // disabled={Object.keys(errors).length > 0}
              style={{
                marginBottom: "174px",
                backgroundColor: "#28bcf7",
                color: "white",
              }}
            />
          </Col>
        </Row>
      </Col>
      <Col
        xl={12}
        lg={12}
        md={12}
        className="right-pannel"
        style={{ backgroundImage: `url(${SVG.registration_logo})` }}
      ></Col>
    </Row>
  );
};

export default ResetPasswordPresentationl;
