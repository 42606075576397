import React from "react";
const PRODUCT_ICON = (
  <>
    <svg viewBox="0 0 17 17" strokeWidth="1" stroke="#fff">
      <g id="Page-1">
        <g
          id="Product1"
          transform="translate(-25.000000, -166.000000)"
          fill="none"
          strokeWidth="1.04033055"
        >
          <g id="Group-11" transform="translate(26.000000, 167.000000)">
            <path
              d="M13.4239957,12.8214516 L7.30360046,16.3108142 C7.27107062,16.3291252 7.2456155,16.3143515 7.24655966,16.2771746 L7.26801878,8.40897681 C7.26905918,8.37284026 7.29340454,8.32706266 7.32496334,8.30979202 L14.3751293,4.212281 C14.4077285,4.19403932 14.4321432,4.20874364 14.4321432,4.24536572 L14.4237507,10.8168784 L14.4220167,12.1831315 C14.4220167,12.2212795 14.3948275,12.2680282 14.3616735,12.2868247 L13.4239957,12.8214516 Z"
              id="Stroke-1"
            ></path>
            <path
              d="M4.87381582,6.96005347 L7.21097018,8.3097296 C7.24252898,8.32811 7.2690245,8.37284719 7.26807933,8.40898375 L7.24662122,16.2771815 C7.24558082,16.3143585 7.22019506,16.3291321 7.18863627,16.3107517 L0.0916522964,12.213865 C0.060093499,12.1955539 0.0336673412,12.1513022 0.0346124434,12.1141253 L0.0560012593,4.24537266 C0.0570416593,4.20875058 0.0824967771,4.19404626 0.114055575,4.21180242 L2.4450369,5.55794119"
              id="Stroke-3"
            ></path>
            <path
              d="M0.114055575,4.14514746 L7.16422155,0.0487462039 C7.19473995,0.0299496455 7.24662122,0.0299496455 7.27922042,0.0487462039 L14.3750946,4.14618786 C14.4076938,4.16394402 14.4076938,4.19404626 14.3750946,4.21228794 L7.32499802,8.3097296"
              id="Stroke-5"
            ></path>
            <line
              x1="2.44506464"
              y1="5.55795506"
              x2="9.60308541"
              y2="1.38983556"
              id="Stroke-7"
            ></line>
            <polyline
              id="Stroke-9"
              points="12.0145798 2.7832501 4.87382969 6.9600396 4.86363377 10.2658756 2.43589525 8.88262928 2.44505077 5.55792731"
            ></polyline>
          </g>
        </g>
      </g>
    </svg>
  </>
);

export default PRODUCT_ICON;
