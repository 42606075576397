import React, { Suspense, useEffect, useState } from "react";
import Routes from "@core/routes/routes";
import { Route, Switch, useHistory, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CommonLazyRouting from "@core/lazy-routing/common-lazy-routing";
import "./layout.scss";
import SVG from "assets/image/svg";
import Footer from "@shared/component/footer";
import PrivateRoute from "routes/PrivateRoute";
import AuthService from "service/auth-service";
import { message } from "antd";

import { MenuOutlined } from "@ant-design/icons";

const getRoutes = (routes = []) => {
  return routes.map((prop, key) => {
    if (prop.children && prop.children.length > 0) {
      return getRoutes(prop.children);
    }
    if (prop.layout === "/") {
      return (
        <PrivateRoute
          {...prop}
          exact={prop.exact}
          key={key}
          path={prop.layout + prop.path + (prop.index ? prop.index : "")}
          roles={prop.permission}
          component={prop.component}
        />
      );
    } else {
      return null;
    }
  });
};

const Layout = () => {
  useEffect(() => {
    setShowMobileMenu(false);
  }, [window.location.pathname]);

  const [role, setRole] = useState("");
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { t } = useTranslation();
  const { VtHeader, VtSidebar } = CommonLazyRouting;

  const history = useHistory();

  useEffect(() => {
    let user = AuthService.getSessionUser();
    setRole(user && user.name && user.name.firstName);
  }, []);

  const getUserType = (roles = []) => {
    const userData = AuthService.getSessionUser() || {};
    return userData && userData.role;
  };
  return (
    <>
      <div className="layout-presentation">
        <div className="vt-admin-layout"></div>
        <VtHeader />
        <div className="vt-container">
          <VtSidebar className="vt-sidebar" Routes={Routes}></VtSidebar>
          <div className="vt-router-container">
            <Switch>
              <Suspense
                fallback={
                  <div>
                    <div className="pageLoader">
                      <img
                        src={SVG.vt_loader}
                        height="80"
                        alt="loader"
                        width="80"
                      />
                    </div>
                  </div>
                }
              >
                {getRoutes(Routes)}
                {/* sidebar section  start*/}
                <PrivateRoute
                  path={"/"}
                  component={
                    AuthService.getSessionUser()?.role === "CS_OPERATOR"
                      ? CommonLazyRouting.OrdersList
                      : CommonLazyRouting.OperatorsList
                  }
                />
                {getUserType() == "ADMIN" && (
                  <>
                    <PrivateRoute
                      path={"/client-list"}
                      component={CommonLazyRouting.ClientList}
                    />
                    <PrivateRoute
                      path={"/client-add"}
                      component={CommonLazyRouting.ClientAdd}
                    />
                    <PrivateRoute
                      path={"/client-edit/:id"}
                      component={CommonLazyRouting.ClientAdd}
                    />
                    <PrivateRoute
                      path={"/discount-add"}
                      component={CommonLazyRouting.DiscountCodeAdd}
                    />
                    <PrivateRoute
                      path={"/discount-edit/:id"}
                      component={CommonLazyRouting.DiscountCodeAdd}
                    />
                    <PrivateRoute
                      path={"/discount-list"}
                      component={CommonLazyRouting.DiscountCodeList}
                    />
                    <PrivateRoute
                      path={"/multiple-discount-add"}
                      component={CommonLazyRouting.MultipleDiscountsAdd}
                    />
                    <PrivateRoute
                      path={"/multiple-discount-edit/:id"}
                      component={CommonLazyRouting.MultipleDiscountsAdd}
                    />
                    <PrivateRoute
                      path={"/multiple-discount-list"}
                      component={CommonLazyRouting.MultipleDiscountsList}
                    />
                    <PrivateRoute
                      path={"/loyalty-points-add"}
                      component={CommonLazyRouting.LoyaltyPointsAdd}
                    />
                    <PrivateRoute
                      path={"/loyalty-points-edit/:id"}
                      component={CommonLazyRouting.LoyaltyPointsAdd}
                    />
                    <PrivateRoute
                      path={"/loyalty-points-list"}
                      component={CommonLazyRouting.LoyaltyPointsList}
                    />
                    <PrivateRoute
                      path={"/loyalty-excel"}
                      component={CommonLazyRouting.LoyalExcel}
                    />
                    <PrivateRoute
                      path={"/upload-excel"}
                      component={CommonLazyRouting.Uploadexcel}
                    />
                    <PrivateRoute
                      path={"/upload-gallery"}
                      component={CommonLazyRouting.UploadGallery}
                    />
                    <PrivateRoute
                      path={"/credit-list"}
                      component={CommonLazyRouting.CreditList}
                    />
                    <PrivateRoute
                      path={"/credit-add"}
                      component={CommonLazyRouting.CreditAdd}
                    />
                    <PrivateRoute
                      path={"/shipping-cost-list"}
                      component={CommonLazyRouting.ShippingCostList}
                    />
                    <PrivateRoute
                      path={"/shipping-cost-add"}
                      component={CommonLazyRouting.ShippingCostAdd}
                    />
                    <PrivateRoute
                      path={"/shipping-cost-edit/:id"}
                      component={CommonLazyRouting.ShippingCostAdd}
                    />
                    <PrivateRoute
                      path={"/news-wall-list"}
                      component={CommonLazyRouting.NewsWallList}
                    />
                    <PrivateRoute
                      path={"/news-wall-add"}
                      component={CommonLazyRouting.NewsWallAdd}
                    />
                    <PrivateRoute
                      path={"/news-wall-edit/:id"}
                      component={CommonLazyRouting.NewsWallList}
                    />
                    <PrivateRoute
                      path={"/coupons-list"}
                      component={CommonLazyRouting.CouponsList}
                    />
                    <PrivateRoute
                      path={"/coupons-add"}
                      component={CommonLazyRouting.CouponsAdd}
                    />
                    <PrivateRoute
                      path={"/gift-card-list"}
                      component={CommonLazyRouting.GiftCardList}
                    />
                    <PrivateRoute
                      path={"/gift-card-add"}
                      component={CommonLazyRouting.GiftCardAdd}
                    />
                    <PrivateRoute
                      path={"/gift-card-edit/:id"}
                      component={CommonLazyRouting.GiftCardAdd}
                    />
                    <PrivateRoute
                      path={"/account-settings"}
                      component={CommonLazyRouting.AccountSettings}
                    />
                    <PrivateRoute
                      path={"/payment-settings"}
                      component={CommonLazyRouting.PaymentSettings}
                    />
                    <PrivateRoute
                      path={"/returns-settings"}
                      component={CommonLazyRouting.ReturnsSettings}
                    />
                    <PrivateRoute
                      path={"/notification-settings"}
                      component={CommonLazyRouting.NotificationSettings}
                    />
                    <PrivateRoute
                      path={"/orders-list"}
                      component={CommonLazyRouting.OrdersList}
                    />
                    <PrivateRoute
                      path={"/order-details/:id"}
                      component={CommonLazyRouting.OrderDetail}
                    />
                    <PrivateRoute
                      path={"/personal-mail-list"}
                      component={CommonLazyRouting.PersonalMailList}
                    />
                    <PrivateRoute
                      path={"/personal-mail-add"}
                      component={CommonLazyRouting.PersonalMailAdd}
                    />
                    <PrivateRoute
                      path={"/operators-add"}
                      component={CommonLazyRouting.OperatorsAdd}
                    />
                    <PrivateRoute
                      path={"/operators-edit/:id"}
                      component={CommonLazyRouting.OperatorsAdd}
                    />
                    <PrivateRoute
                      path={"/operators-list"}
                      component={CommonLazyRouting.OperatorsList}
                    />
                    <PrivateRoute
                      path={"/notification-list"}
                      component={CommonLazyRouting.NotificationList}
                    />
                    <PrivateRoute
                      path={"/notification-table"}
                      component={CommonLazyRouting.NotificationTable}
                    />
                    <PrivateRoute
                      path={"/reset-password"}
                      component={CommonLazyRouting.ResetPassword}
                    />
                    <PrivateRoute
                      path={"/refund-list"}
                      component={CommonLazyRouting.RefundList}
                    />
                    <PrivateRoute
                      path={"/refund-detail"}
                      component={CommonLazyRouting.RefundDetail}
                    />
                    <PrivateRoute
                      path={"/refund-detail/:id"}
                      component={CommonLazyRouting.RefundDetail}
                    />
                    <PrivateRoute
                      path={"/operator-access"}
                      component={CommonLazyRouting.OperatorAccess}
                    />
                  </>
                )}
                {getUserType() == "CS_OPERATOR" && (
                  <Switch>
                    <PrivateRoute
                      path={"/discount-add"}
                      component={CommonLazyRouting.DiscountCodeAdd}
                    />
                    <PrivateRoute
                      path={"/discount-edit/:id"}
                      component={CommonLazyRouting.DiscountCodeAdd}
                    />
                    <PrivateRoute
                      path={"/discount-list"}
                      component={CommonLazyRouting.DiscountCodeList}
                    />
                    <PrivateRoute
                      path={"/orders-list"}
                      component={CommonLazyRouting.OrdersList}
                    />
                    <PrivateRoute
                      path={"/order-details/:id"}
                      component={CommonLazyRouting.OrderDetail}
                    />
                    <PrivateRoute
                      path={"/news-wall-list"}
                      component={CommonLazyRouting.NewsWallList}
                    />
                    <PrivateRoute
                      path={"/News-wall-edit/:id"}
                      component={CommonLazyRouting.NewsWallList}
                    />
                    <Redirect to="/login" />
                  </Switch>
                )}
              </Suspense>
            </Switch>
          </div>
        </div>

        <Footer />
      </div>
      <div className="layout-mobile-presentation">
        <div className="mobile-header">
          <span>{role}</span>
          <MenuOutlined
            style={{ fontSize: 20, color: "#fff" }}
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          />
        </div>
        {showMobileMenu && (
          <div className="sidebar">
            <VtSidebar className="vt-sidebar" Routes={Routes}></VtSidebar>
          </div>
        )}
        <div className="vt-router-container">
          <Switch>
            <Suspense
              fallback={
                <div>
                  <div className="pageLoader">
                    <img
                      src={SVG.vt_loader}
                      height="80"
                      alt="loader"
                      width="80"
                    />
                  </div>
                </div>
              }
            >
              {getRoutes(Routes)}
              {/* sidebar section  start*/}
              <PrivateRoute
                path={"/"}
                component={
                  AuthService.getSessionUser()?.role === "CS_OPERATOR"
                    ? CommonLazyRouting.OrdersList
                    : CommonLazyRouting.OperatorsList
                }
              />
              {getUserType() == "ADMIN" && (
                <>
                  <PrivateRoute
                    path={"/client-list"}
                    component={CommonLazyRouting.ClientList}
                  />
                  <PrivateRoute
                    path={"/client-add"}
                    component={CommonLazyRouting.ClientAdd}
                  />
                  <PrivateRoute
                    path={"/client-edit/:id"}
                    component={CommonLazyRouting.ClientAdd}
                  />
                  <PrivateRoute
                    path={"/discount-add"}
                    component={CommonLazyRouting.DiscountCodeAdd}
                  />
                  <PrivateRoute
                    path={"/discount-edit/:id"}
                    component={CommonLazyRouting.DiscountCodeAdd}
                  />
                  <PrivateRoute
                    path={"/discount-list"}
                    component={CommonLazyRouting.DiscountCodeList}
                  />
                  <PrivateRoute
                    path={"/multiple-discount-add"}
                    component={CommonLazyRouting.MultipleDiscountsAdd}
                  />
                  <PrivateRoute
                    path={"/multiple-discount-edit/:id"}
                    component={CommonLazyRouting.MultipleDiscountsAdd}
                  />
                  <PrivateRoute
                    path={"/multiple-discount-list"}
                    component={CommonLazyRouting.MultipleDiscountsList}
                  />
                  <PrivateRoute
                    path={"/loyalty-points-add"}
                    component={CommonLazyRouting.LoyaltyPointsAdd}
                  />
                  <PrivateRoute
                    path={"/loyalty-points-edit/:id"}
                    component={CommonLazyRouting.LoyaltyPointsAdd}
                  />
                  <PrivateRoute
                    path={"/loyalty-points-list"}
                    component={CommonLazyRouting.LoyaltyPointsList}
                  />
                  <PrivateRoute
                    path={"/loyalty-excel"}
                    component={CommonLazyRouting.LoyalExcel}
                  />
                  <PrivateRoute
                    path={"/upload-excel"}
                    component={CommonLazyRouting.Uploadexcel}
                  />
                  <PrivateRoute
                    path={"/upload-gallery"}
                    component={CommonLazyRouting.UploadGallery}
                  />
                  <PrivateRoute
                    path={"/credit-list"}
                    component={CommonLazyRouting.CreditList}
                  />
                  <PrivateRoute
                    path={"/credit-add"}
                    component={CommonLazyRouting.CreditAdd}
                  />
                  <PrivateRoute
                    path={"/shipping-cost-list"}
                    component={CommonLazyRouting.ShippingCostList}
                  />
                  <PrivateRoute
                    path={"/shipping-cost-add"}
                    component={CommonLazyRouting.ShippingCostAdd}
                  />
                  <PrivateRoute
                    path={"/shipping-cost-edit/:id"}
                    component={CommonLazyRouting.ShippingCostAdd}
                  />
                  <PrivateRoute
                    path={"/news-wall-list"}
                    component={CommonLazyRouting.NewsWallList}
                  />
                  <PrivateRoute
                    path={"/news-wall-add"}
                    component={CommonLazyRouting.NewsWallAdd}
                  />
                  <PrivateRoute
                    path={"/news-wall-edit/:id"}
                    component={CommonLazyRouting.NewsWallList}
                  />
                  <PrivateRoute
                    path={"/coupons-list"}
                    component={CommonLazyRouting.CouponsList}
                  />
                  <PrivateRoute
                    path={"/coupons-add"}
                    component={CommonLazyRouting.CouponsAdd}
                  />
                  <PrivateRoute
                    path={"/gift-card-list"}
                    component={CommonLazyRouting.GiftCardList}
                  />
                  <PrivateRoute
                    path={"/gift-card-add"}
                    component={CommonLazyRouting.GiftCardAdd}
                  />
                  <PrivateRoute
                    path={"/gift-card-edit/:id"}
                    component={CommonLazyRouting.GiftCardAdd}
                  />
                  <PrivateRoute
                    path={"/account-settings"}
                    component={CommonLazyRouting.AccountSettings}
                  />
                  <PrivateRoute
                    path={"/payment-settings"}
                    component={CommonLazyRouting.PaymentSettings}
                  />
                  <PrivateRoute
                    path={"/returns-settings"}
                    component={CommonLazyRouting.ReturnsSettings}
                  />
                  <PrivateRoute
                    path={"/notification-settings"}
                    component={CommonLazyRouting.NotificationSettings}
                  />
                  <PrivateRoute
                    path={"/orders-list"}
                    component={CommonLazyRouting.OrdersList}
                  />
                  <PrivateRoute
                    path={"/order-details/:id"}
                    component={CommonLazyRouting.OrderDetail}
                  />
                  <PrivateRoute
                    path={"/personal-mail-list"}
                    component={CommonLazyRouting.PersonalMailList}
                  />
                  <PrivateRoute
                    path={"/personal-mail-add"}
                    component={CommonLazyRouting.PersonalMailAdd}
                  />
                  <PrivateRoute
                    path={"/operators-add"}
                    component={CommonLazyRouting.OperatorsAdd}
                  />
                  <PrivateRoute
                    path={"/operators-edit/:id"}
                    component={CommonLazyRouting.OperatorsAdd}
                  />
                  <PrivateRoute
                    path={"/operators-list"}
                    component={CommonLazyRouting.OperatorsList}
                  />
                  <PrivateRoute
                    path={"/notification-list"}
                    component={CommonLazyRouting.NotificationList}
                  />
                  <PrivateRoute
                    path={"/notification-table"}
                    component={CommonLazyRouting.NotificationTable}
                  />
                  <PrivateRoute
                    path={"/reset-password"}
                    component={CommonLazyRouting.ResetPassword}
                  />
                  <PrivateRoute
                    path={"/refund-list"}
                    component={CommonLazyRouting.RefundList}
                  />
                  <PrivateRoute
                    path={"/refund-detail"}
                    component={CommonLazyRouting.RefundDetail}
                  />
                  <PrivateRoute
                    path={"/refund-detail/:id"}
                    component={CommonLazyRouting.RefundDetail}
                  />
                  <PrivateRoute
                    path={"/operator-access"}
                    component={CommonLazyRouting.OperatorAccess}
                  />
                </>
              )}
              {getUserType() == "CS_OPERATOR" && (
                <Switch>
                  <PrivateRoute
                    path={"/discount-add"}
                    component={CommonLazyRouting.DiscountCodeAdd}
                  />
                  <PrivateRoute
                    path={"/discount-edit/:id"}
                    component={CommonLazyRouting.DiscountCodeAdd}
                  />
                  <PrivateRoute
                    path={"/discount-list"}
                    component={CommonLazyRouting.DiscountCodeList}
                  />
                  <PrivateRoute
                    path={"/orders-list"}
                    component={CommonLazyRouting.OrdersList}
                  />
                  <PrivateRoute
                    path={"/order-details/:id"}
                    component={CommonLazyRouting.OrderDetail}
                  />
                  <PrivateRoute
                    path={"/news-wall-list"}
                    component={CommonLazyRouting.NewsWallList}
                  />
                  <PrivateRoute
                    path={"/News-wall-edit/:id"}
                    component={CommonLazyRouting.NewsWallList}
                  />
                  <Redirect to="/login" />
                </Switch>
              )}
            </Suspense>
          </Switch>
        </div>
      </div>
    </>
  );
};

export default Layout;
