import { lazy } from "react";

const NotFound = lazy(() =>
  import("../../@shared/component/vt-not-found/vt-not-found")
);

const RegistartionFunctional = lazy(() =>
  import("../../@pages/Registration/registration-functional")
);
const LoginFunctional = lazy(() =>
  import("../../@pages/login/login-functional")
);

const PasswordResetModal = lazy(() =>
  import("../../@shared/modals/reset-password/reset-password-modals")
);

const VtSidebar = lazy(() =>
  import("../../@shared/component/vt-sidebar/vt-sidebar")
);

const VtHeader = lazy(() =>
  import("../../@shared/component/vt-header/vt-header")
);

const VtLabel = lazy(() => import("../../@shared/component/vt-label/vt-label"));

const ClientAdd = lazy(() =>
  import("../../@pages/client/client-add/client-add-functional")
);
const ClientList = lazy(() =>
  import("../../@pages/client/client-list/client-list-functional")
);
const DiscountCodeAdd = lazy(() =>
  import(
    "../../@pages/discount-codes/discount-code-add/discount-code-add-functional"
  )
);
const DiscountCodeList = lazy(() =>
  import(
    "../../@pages/discount-codes/discount-code-list/discount-code-list-functional"
  )
);
const MultipleDiscountsAdd = lazy(() =>
  import(
    "../../@pages/multiple-discounts/multiple-discounts-add/multiple-discounts-add-functional"
  )
);
const MultipleDiscountsList = lazy(() =>
  import(
    "../../@pages/multiple-discounts/multiple-discounts-list/multiple-discounts-list-functional"
  )
);
const LoyaltyPointsAdd = lazy(() =>
  import(
    "../../@pages/loyalty-points/loyalty-points-add/loyalty-points-add-functional"
  )
);
const LoyaltyPointsList = lazy(() =>
  import(
    "../../@pages/loyalty-points/loyalty-points-list/loyalty-points-list-functional"
  )
);
const LoyalExcel = lazy(() =>
  import("../../@pages/loyalty-points/excel/loyal-excel-functional")
);
const Uploadexcel = lazy(() =>
  import("../../@pages/upload-products/excel/excel-functional")
);
const UploadGallery = lazy(() =>
  import("../../@pages/upload-products/gallery/gallery-functional")
);
const CreditAdd = lazy(() =>
  import("../../@pages/credit/credit-add/credit-add-functional")
);
const CreditList = lazy(() =>
  import("../../@pages/credit/credit-list/credit-list-functional")
);
const ShippingCostAdd = lazy(() =>
  import(
    "../../@pages/shipping/cost/shipping-cost-add/shipping-cost-add-functional"
  )
);
const ShippingCostList = lazy(() =>
  import(
    "../../@pages/shipping/cost/shipping-cost-list/shipping-cost-list-functional"
  )
);
const NewsWallAdd = lazy(() =>
  import("../../@pages/news-wall/news-wall-add/news-wall-add-functional")
);
const NewsWallList = lazy(() =>
  import("../../@pages/news-wall/news-wall-list/news-wall-list-functional")
);
const CouponsAdd = lazy(() =>
  import("../../@pages/coupons/coupons-add/coupons-add-functional.js")
);
const CouponsList = lazy(() =>
  import("../../@pages/coupons/coupons-list/coupons-list-functional.js")
);
const GiftCardAdd = lazy(() =>
  import("../../@pages/gift-cards/gift-card-add/gift-card-add-functional")
);
const GiftCardList = lazy(() =>
  import("../../@pages/gift-cards/gift-card-list/gift-card-list-functional")
);
const AccountSettings = lazy(() =>
  import("../../@pages/settings/account-settings/account-settings-functional")
);
const PaymentSettings = lazy(() =>
  import("../../@pages/settings/payments-settings/payment-settings-functional")
);
const ReturnsSettings = lazy(() =>
  import("../../@pages/settings/returns-settings/returns-settings-functional")
);
const OrdersSettings = lazy(() =>
  import("../../@pages/orders/orders-list/orders-list-functional")
);

const NotificationSettings = lazy(() =>
  import(
    "../../@pages/settings/notification-settings/notification-settings-functional"
  )
);
const OrdersList = lazy(() =>
  import("../../@pages/orders/orders-list/orders-list-functional")
);
const OperatorsList = lazy(() =>
  import("../../@pages/operators/operators-list/operators-list-functional")
);
const OperatorsAdd = lazy(() =>
  import("../../@pages/operators/operators-add/operators-add-functional")
);
const OrderDetail = lazy(() =>
  import("../../@pages/order-detail/order-detail-functional")
);
const ForgetPassword = lazy(() =>
  import("../../@pages/forget-password/forget-password-functional")
);
const ResetPassword = lazy(() =>
  import("../../@pages/reset-password/reset-password-functional")
);
const NotificationList = lazy(() =>
  import(
    "../../@pages/notification/notification-list/notification-list-functional"
  )
);
const NotificationTable = lazy(() =>
  import(
    "../../@pages/notification/notification-table/notification-table-functional"
  )
);
const RefundList = lazy(() =>
  import("../../@pages/refund/refund-list/refund-list-functional")
);
const RefundDetail = lazy(() =>
  import("../../@pages/refund/refund-detail/refund-detail-functional")
);
const OperatorAccess = lazy(() =>
  import("../../@pages/operator-access/operator-access-functional")
);
const Error404 = lazy(() => import("../../@pages/Error-404/Error-404"));

const CommonLazyRouting = {
  NotFound,
  PasswordResetModal,
  VtSidebar,
  VtHeader,
  VtLabel,
  LoginFunctional,
  ClientAdd,
  ClientList,
  DiscountCodeAdd,
  DiscountCodeList,
  MultipleDiscountsAdd,
  LoyaltyPointsAdd,
  MultipleDiscountsList,
  LoyaltyPointsList,
  LoyalExcel,
  Uploadexcel,
  UploadGallery,
  RegistartionFunctional,
  CreditList,
  CreditAdd,
  ShippingCostAdd,
  ShippingCostList,
  NewsWallAdd,
  NewsWallList,
  CouponsAdd,
  CouponsList,
  GiftCardAdd,
  GiftCardList,
  AccountSettings,
  PaymentSettings,
  ReturnsSettings,
  NotificationSettings,
  OrdersList,
  OperatorsList,
  OperatorsAdd,
  OrderDetail,
  ForgetPassword,
  ResetPassword,
  NotificationList,
  NotificationTable,
  RefundList,
  RefundDetail,
  OperatorAccess,
  Error404,
};

export default CommonLazyRouting;
