import React, { useEffect } from "react";
import { Row, Divider, Col, Radio } from "antd";
import SVG from "./../../assets/image/svg/index";
import "./forget-password.scss";
import VtTextField from "@shared/component/vt-textfield/vt-text-field";
import VtButton from "@shared/component/vt-button/vt-button";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import validator from "@shared/validators/validator";
import VtFormErrMsg from "@shared/component/vt-form-err-msg/vt-form-err-msg";
import VtPassword from "@shared/component/vt-password/vt-password";
import { useTranslation } from "react-i18next";
import VtLabel from "@shared/component/vt-label/vt-label";

const ForgetPasswordPresentationl = ({
  sendEmailClicked,
  email,
  setEmail,
  btnLoading = false,
  backToLogin,
}) => {
  const { t } = useTranslation();

  const { control, errors, trigger, setValue } = useForm({
    defaultValues: { email: null },
    mode: "all",
  });

  useEffect(() => {
    setValue("email", email);
  }, [email]);

  return (
    <Row className="forget-wrapper">
      <Col xl={12} lg={12} md={12} sm={24} xs={24} className="left-pannel">
        <Row className="left-row">
          <Col xl={24} lg={24} md={24} sm={24} xs={24} className="alley-logo">
            <Link
              to={{
                pathname: "/",
              }}
            >
              <img src={SVG.alley33_logo} alt="alley33_logo" />
            </Link>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24} className="accedi">
            {t("LOGIN_PAGE.COMMON.FORGOT_PASSWORD")}
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Controller
              control={control}
              name="email"
              rules={{
                required: true,
                pattern: validator.EMAIL,
              }}
              render={({ ref, ...rest }) => (
                <VtTextField
                  {...rest}
                  ref={ref}
                  type="email"
                  placeholder={t("COMMON.EMAIL")}
                  label={t("COMMON.EMAIL")}
                  value={email}
                  onInput={(e) => setEmail(e.target.value)}
                />
              )}
            />
            {errors.email && errors.email.type == "required" && (
              <div className="required">This field is required</div>
            )}
            {errors.email && errors.email.type == "pattern" && (
              <div className="required">Please enter a valid email format</div>
            )}
          </Col>

          <Col>
            <Link
              to={{
                pathname: "/login",
              }}
            >
              {t("COMMON.LOGIN")}
            </Link>
          </Col>
          <Col
            xl={24}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            style={{ marginTop: "5px" }}
          >
            <VtButton
              name={t("COMMON.SEND")}
              block
              type="red"
              loading={btnLoading}
              onClick={() => {
                trigger().then((res) => {
                  if (res) {
                    sendEmailClicked();
                  }
                });
              }}
              block
              disabled={
                email == "" || email == null || Object.keys(errors).length > 0
              }
              style={{
                marginBottom: "174px",
                backgroundColor: "#28bcf7",
                color: "white",
              }}
            />
          </Col>
        </Row>
      </Col>

      <Col
        xl={12}
        lg={12}
        md={12}
        className="right-pannel"
        style={{ backgroundImage: `url(${SVG.registration_logo})` }}
      ></Col>
    </Row>
  );
};

export default ForgetPasswordPresentationl;
