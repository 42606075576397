import URLs from "config/environment";

//login urls
export const LoginUrl = {
  GET_CLIENT_DETAIL_BY_TOKEN: "/api/v1/session/user",
  LOG_IN: URLs.apiUrl + "/api/v1/user/authenticate",
  LOGOUT: URLs.apiUrl + "/api/v1/user/logout",
  FORGET_PASSWORD: URLs.apiAuthUrl + "/api/v1/user/password",
  RESET_PASSWORD: URLs.apiAuthUrl + "/api/v1/user/password/reset",
};

//client & cs Operator urls
export const ClientUrl = {
  ADD_CLIENT: URLs.apiUrl + "/api/v1/user",
  GET_CLIENT: URLs.apiUrl + "/api/v1/user",
  UPDATE_CLIENT: URLs.apiUrl + "/api/v1/user",
  DELETE_CLIENT: URLs.apiUrl + "/api/v1/user",
  UPDATE_CREDIT: URLs.apiUrl + "/api/v1/credits",
};

//loyalty points url
export const LoyaltytUrl = {
  ADD_LOYALTY_POINTS: URLs.apiUrl + "/api/v1/fidelity-point",
  GET_LOYALTY_POINTS: URLs.apiUrl + "/api/v1/fidelity-point",
  UPDATE_LOYALTY_POINTS: URLs.apiUrl + "/api/v1/user",
  DELETE_LOYALTY_POINTS: URLs.apiUrl + "/api/v1/user",
  FILE_DOWNLOAD: URLs.baseUrl + "/api/v1/file",
  FILE_UPLOAD: URLs.apiUrl + "/api/v1/fidelity-upload",
};
//orders url
export const OrdersUrl = {
  ADD_ORDERS: URLs.baseUrl + "/api/v1/order-checkout",
  GET_ORDERS: URLs.baseUrl + "/api/v1/order",
  UPDATE_ORDERS: URLs.baseUrl + "/api/v1/order/",
  ORDERS_STATUS: URLs.baseUrl + "/api/v1/order-status",
};
//coupons urls
export const CouponsUrl = {
  ADD_COUPONS: URLs.baseUrl + "/api/v1/promo-code",
  GET_COUPONS: URLs.baseUrl + "/api/v1/promo-code",
  UPDATE_COUPONS: URLs.baseUrl + "/api/v1/order",
  DELETE_LOYALTY_POINTS: URLs.apiUrl + "/api/v1/user",
};

//gift card urls
export const GiftCard = {
  ADD_GIFTCARD: URLs.baseUrl + "/api/v1/gift-card",
  GET_GIFTCARD: URLs.baseUrl + "/api/v1/product",
  UPDATE_GIFTCARD: URLs.baseUrl + "/api/v1/gift-card",
  DELETE_GIFTCARD: URLs.baseUrl + "/api/v1/gift-card",
  POST_GIFTCARD: URLs.baseUrl + "/api/v1/gift-card",
};

//discount code urls
export const DiscountCodeUrl = {
  ADD_DISCOUNT_CODE: URLs.baseUrl + "/api/v1/promo-code",
  GET_DISCOUNT_CODE: URLs.baseUrl + "/api/v1/promo-code",
  UPDATE_DISCOUNT_CODE: URLs.baseUrl + "/api/v1/promo-code",
  DELETE_DISCOUNT_CODE: URLs.baseUrl + "/api/v1/promo-code",
  STATUS_DISCOUNT: URLs.baseUrl + "/api/v1/promo-code-status",
};
//multiplediscount code urls
export const MultipleDiscountCodeUrl = {
  ADD_MULTIPLEDISCOUNT_CODE: URLs.baseUrl + "/api/v1/discount/",
  GET_MULTIPLEDISCOUNT_CODE: URLs.baseUrl + "/api/v1/discount",
  UPDATE_MULTIPLEDISCOUNT_CODE: URLs.baseUrl + "/api/v1/discount",
  DELETE_MULTIPLEDISCOUNT_CODE: URLs.baseUrl + "/api/v1/discount",
  GET_SEASON: URLs.baseUrl + "/api/v1/product-season",
  GET_SECTOR: URLs.baseUrl + "/api/v1/sector",
  GET_BRAND: URLs.baseUrl + "/api/v1/product/brand",
  GET_CATEGORY: URLs.baseUrl + "/api/v1/category",
  GET_SUBCATEGORY: URLs.baseUrl + "/api/v1/subcategory",
};

// url: "http://194.60.201.228:8002/svc-product/api/v1/promo-code",

//shipping urls
export const ShippingUrl = {
  ADD_SHIPPING: URLs.baseUrl + "/api/v1/shipment-cost",
  GET_SHIPPING: URLs.baseUrl + "/api/v1/shipment-cost",
  UPDATE_SHIPPING: URLs.baseUrl + "/api/v1/shipment-cost",
  DELETE_SHIPPING: URLs.baseUrl + "/api/v1/shipment-cost",
  POST_SHIPPING: URLs.baseUrl + "/api/v1/shipment-cost",
};

//settings urls
export const SettingsURL = {
  GET_SETTINGS: URLs.baseUrl + "/api/v1/settings/1",
  ADD_SETTINGS: URLs.baseUrl + "/api/v1/settings/1",
  HOME_GALLERY: URLs.baseUrl + "/api/v1/home-gallery",
};

export const UploadProductURL = {
  GET_GALLERY: URLs.baseUrl + "/api/v1/product-gallery",
  GET_PRODUCT: URLs.baseUrl + "/api/v1/product-code",
  UPLOAD_FILE: URLs.baseUrl + "/api/v1/product-gallery",
  UPLOAD_GALLERY: URLs.baseUrl + "/api/v1/product-gallery",
};

export const notificationURL = {
  GET_NOTIFICATION: URLs.apiAuthUrl + "/api/v1/notification",
  ADD_NOTIFICATION: URLs.apiAuthUrl + "/api/v1/notification",
};

export const NewsWallURL = {
  GET_NEWSWALL: URLs.apiAuthUrl + "/api/v1/wall",
  ADD_NEWSWALL: URLs.apiAuthUrl + "/api/v1/wall",
  ADD_WALL: URLs.apiAuthUrl + "/api/v1/wall-post",
};

export const RefundURL = {
  GET_REFUND: URLs.baseUrl + "/api/v1/refund",
  ADD_REFUND: URLs.baseUrl + "/api/v1/refund",
  UPDATE_REFUND_STATUS: URLs.baseUrl + "/api/v1/refund",
};

export const SidebarURL = {
  GET_LIST: URLs.apiAuthUrl + "/api/v1/menu",
  GET_OPERATORLIST: URLs.apiAuthUrl + "/api/v1/menu-access",
};
