import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
// import AdminLayout from "@layouts/admin-layout/admin-layout";
import LoginFunctional from "@pages/login/login-functional";
import Layout from "@layouts/layout";
import CommonLazyRouting from "@core/lazy-routing/common-lazy-routing";
import ForgetPasswordFunctional from "@pages/forget-password/forget-password-functional";
import ResetPassword from "@pages/reset-password/reset-password-functional";
import Error404 from "@pages/Error-404/Error-404";
import ErrorInterceptor from "@core/interceptors/error-interceptor";

const Routes = () => {
  const history = useHistory();
  ErrorInterceptor({ history });
  return (
    <Switch>
      <Route path={"/login"} component={LoginFunctional} exact={true} />
      <Route path={"/Error404"} component={CommonLazyRouting.Error404} />
      <Route
        path={"/forget-password"}
        component={CommonLazyRouting.ForgetPassword}
      />
      <Route
        path={"/reset-password"}
        component={CommonLazyRouting.ResetPassword}
      />
      {/* <Route path={"/login"} component={LoginFunctional} exact={true} /> */}
      {/* <Route path={"/Registration"} component={Registration} exact={true} /> */}
      <Route path={"/"} component={Layout} />
      <Redirect from="" to="/login" />
      <Redirect from="*" to="/login" />
    </Switch>
  );
};

export default Routes;
