import React from "react";
const CATEGORY_ICON = (
  <>
    <svg viewBox="0 0 17 17" strokeWidth="1">
      <g id="Page-1">
        <g
          id="Product1"
          transform="translate(-25.000000, -308.000000)"
          fill="#FFFFFF"
        >
          <g id="list" transform="translate(25.000000, 308.000000)">
            <path
              d="M0,3.48133656 L3.48133656,3.48133656 L3.48133656,0 L0,0 L0,3.48133656 Z M0.921978618,0.921978618 L2.55947797,0.921978618 L2.55947797,2.55947797 L0.921978618,2.55947797 L0.921978618,0.921978618 Z"
              id="Shape"
            ></path>
            <path
              d="M4.5876869,0 L4.5876869,3.48133656 L15.7330533,3.48133656 L15.7330533,0 L4.5876869,0 Z M14.8110747,2.55947797 L5.50966552,2.55947797 L5.50966552,0.921978618 L14.8110747,0.921978618 L14.8110747,2.55947797 Z"
              id="Shape"
            ></path>
            <path
              d="M0,7.99868372 L3.48133656,7.99868372 L3.48133656,4.51734715 L0,4.51734715 L0,7.99868372 Z M0.921978618,5.43920575 L2.55947797,5.43920575 L2.55947797,7.0767051 L0.921978618,7.0767051 L0.921978618,5.43920575 Z"
              id="Shape"
            ></path>
            <path
              d="M4.5876869,7.99868372 L15.7330533,7.99868372 L15.7330533,4.51734715 L4.5876869,4.51734715 L4.5876869,7.99868372 Z M5.50966552,5.43920575 L14.8110747,5.43920575 L14.8110747,7.0767051 L5.50966552,7.0767051 L5.50966552,5.43920575 Z"
              id="Shape"
            ></path>
            <path
              d="M0,12.5160309 L3.48133656,12.5160309 L3.48133656,9.03457431 L0,9.03457431 L0,12.5160309 Z M0.921978618,9.95655293 L2.55947797,9.95655293 L2.55947797,11.5940523 L0.921978618,11.5940523 L0.921978618,9.95655293 Z"
              id="Shape"
            ></path>
            <path
              d="M4.5876869,12.5160309 L15.7330533,12.5160309 L15.7330533,9.03457431 L4.5876869,9.03457431 L4.5876869,12.5160309 Z M5.50966552,9.95655293 L14.8110747,9.95655293 L14.8110747,11.5940523 L5.50966552,11.5940523 L5.50966552,9.95655293 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </>
);

export default CATEGORY_ICON;
