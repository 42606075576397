let apiUrl = "https://alley33.it/svc-user";
let baseUrl = "https://alley33.it/svc-product";
//let apiUrl = "https://dashboard.alley33-dev.click/svc-user";
//let baseUrl = "https://dashboard.alley33-dev.click/svc-product";

let apiAuthUrl = "";

const URLs = {
  apiUrl,
  baseUrl,
  apiAuthUrl,
};

export default URLs;
