import React from "react";
const EDIT_ICON = (
  <>
    <svg strokeWidth="1" viewBox="0 0 17 14">
      <g id="Page-1" stroke="none" strokeWidth="1">
        <g id="Technlogy2" transform="translate(-1226.000000, -214.000000)">
          <g id="Group-7" transform="translate(276.500000, 206.000000)">
            <g id="Group-23" transform="translate(0.500000, 0.000000)">
              <g id="Group-22" transform="translate(941.000000, 0.000000)">
                <g id="Group-19" transform="translate(9.599999, 8.000000)">
                  <path
                    d="M10.3764983,6.55317393 C10.0744983,6.55317393 9.83039839,6.7978739 9.83039839,7.09927384 L9.83039839,11.4683731 C9.83039839,11.7692731 9.58569842,12.014473 9.28429848,12.014473 L1.63839973,12.014473 C1.33689977,12.014473 1.09229983,11.7692731 1.09229983,11.4683731 L1.09229983,3.82247438 C1.09229983,3.52157443 1.33689977,3.27637447 1.63839973,3.27637447 L6.00749902,3.27637447 C6.30949897,3.27637447 6.55359893,3.0316745 6.55359893,2.73027457 C6.55359893,2.42877461 6.30949897,2.18407464 6.00749902,2.18407464 L1.63839973,2.18407464 C0.735099886,2.18407464 2.13162808e-15,2.91917453 2.13162808e-15,3.82247438 L2.13162808e-15,11.4683731 C2.13162808e-15,12.371673 0.735099886,13.1067729 1.63839973,13.1067729 L9.28429848,13.1067729 C10.1875983,13.1067729 10.9226982,12.371673 10.9226982,11.4683731 L10.9226982,7.09927384 C10.9226982,6.79727389 10.6784982,6.55317393 10.3764983,6.55317393 Z"
                    id="Path"
                  ></path>
                  <path
                    d="M5.12049915,6.05567401 C5.08229917,6.09387402 5.05659918,6.14247399 5.04569917,6.19487399 L4.65959924,8.12607367 C4.64159924,8.21557366 4.66999923,8.30787365 4.73439922,8.37287363 C4.78629922,8.42477363 4.85619919,8.45257364 4.92779921,8.45257364 C4.94519919,8.45257364 4.96329918,8.45097364 4.98129918,8.44717362 L6.91189887,8.06107369 C6.96539887,8.05007368 7.01399885,8.02447369 7.05169884,7.98617371 L11.3726981,3.66517441 L9.44209845,1.73467473 L5.12049915,6.05567401 Z"
                    id="Path"
                  ></path>
                  <path
                    d="M12.7073979,0.39937493 C12.174998,-0.133124977 11.3087981,-0.133124977 10.7767982,0.39937493 L10.0209983,1.15517482 L11.951598,3.08577449 L12.7073979,2.32987463 C12.9651979,2.07267467 13.1071979,1.72967472 13.1071979,1.36487478 C13.1071979,1.00007484 12.9651979,0.657074907 12.7073979,0.39937493 Z"
                    id="Path"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>{" "}
    </svg>
  </>
);
export default EDIT_ICON;
