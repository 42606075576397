import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import tokenInterceptor from "@core/interceptors/token-interceptor";
import "@core/i18n/i18n";
import "./index.css";
import "antd/dist/antd.css";
import "assets/scss/style.scss";
import "assets/scss/mobile.scss";
import RootStore from "@shared/store/create-store";
import { Provider } from "react-redux";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import SVG from "assets/image/svg";
require("dotenv").config();

tokenInterceptor();

const store = RootStore;

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Suspense
      fallback={
        <div>
          <div className="pageLoader" style={{ height: "100vh" }}>
            <img src={SVG.vt_loader} height="80" width="80" />
          </div>
        </div>
      }
    >
      <App />
    </Suspense>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
